import React, { ReactNode, useCallback } from 'react'
import DataType from '../../types/data.type'

import { Checkbox, Text } from 'components'
import { get } from 'lodash'
import { DEFAULT_EMPTY_MSG, DEFAULT_INITIAL_SEARCH_MSG } from 'shared/components/list/constants'
import { classesBuilder } from 'shared/utils'
import style from 'theme/components/webform/select/select.module.scss'
import Grid from '../../../../../shared/components/context/grid'

export type ColumnSelectOption = {
  title: string
  path: string
  custom?: (option: DataType) => ReactNode
}

type BaseProps = {
  data?: DataType[] | null
  selecteds?: DataType[] | null
  onClick?: Function
  multiple?: boolean
  customOption?: (option: DataType) => ReactNode
  columns?: ColumnSelectOption[]
  isRequest?: boolean
  requestFinished?: boolean
  customMessageNotFound?: string
  customMessageInitial?: string
}

const Base: React.FC<BaseProps> = ({
  data,
  onClick,
  selecteds,
  multiple,
  customOption,
  columns,
  isRequest,
  requestFinished,
  customMessageNotFound,
  customMessageInitial,
}) => {
  const isSelected = useCallback(
    (line: DataType): boolean =>
      selecteds?.some((selected) => selected.value === line.value) ?? false,
    [selecteds],
  )

  const isLineDisabled = (disabled: boolean | undefined): boolean => {
    if (disabled === undefined && disabled === false) {
      return false
    }
    return true
  }

  return (
    <>
      {data && data.length === 0 && (
        <div className={style.list}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className={style.line}>
              <Text value="" rounded />
            </div>
          ))}
        </div>
      )}

      {data?.length && !columns?.length
        ? data.map((line) => (
            <div
              key={line.value}
              className={classesBuilder(style, {
                line: true,
                ...(!multiple ? { selected: isSelected(line) } : null),
                ...(line.disabled ? { disabled: isLineDisabled(line.disabled) } : null),
              })}
              {...(onClick && line.disabled !== true ? { onClick: () => onClick?.(line) } : null)}
              title={ line.title }
            >
              {multiple && (
                <div className={style.checkbox}>
                  <Checkbox small checked={isSelected(line)} disabled={line.disabled} />
                </div>
              )}
              {customOption ? customOption(line) : line.text}
            </div>
          ))
        : null}

      {data?.length && columns?.length ? (
        <>
          {!!data.length && (
            <div className={style.header}>
              <div className={style.line}>
                {columns.map((column) => (
                  <div key={column.title} className={style.cell}>
                    <Grid alignItems="center">{column.title}</Grid>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!data.length && (
            <div className={style.body}>
              {(data || []).map((line, indexLine) => (
                <div
                  key={`line_${indexLine}`}
                  className={classesBuilder(style, {
                    line: true,
                    ...(!multiple ? { selected: isSelected(line) } : null),
                  })}
                  {...(onClick ? { onClick: () => onClick?.(line) } : null)}
                >
                  {columns.map((column, indexCell) => (
                    <div key={`line_${indexLine}_cell_${indexCell}`} className={style.cell}>
                      {column.custom?.(line) || (column.path && get(line, column.path, ''))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </>
      ) : null}

      {!data && isRequest ? (
        <>
          {requestFinished ? (
            <div className={style.empty}>
              <Text
                size="medium"
                icon="alert-triangle-outline"
                iconColor="warning"
                value={customMessageNotFound || DEFAULT_EMPTY_MSG}
              />
            </div>
          ) : (
            <div className={style.empty}>
              <Text
                size="medium"
                icon="alert-triangle-outline"
                iconColor="warning"
                value={customMessageInitial || DEFAULT_INITIAL_SEARCH_MSG}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {!data && (
            <div className={style.empty}>
              <Text
                size="medium"
                icon="alert-triangle-outline"
                iconColor="warning"
                value={customMessageNotFound || DEFAULT_EMPTY_MSG}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Base
